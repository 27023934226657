import { NewFileProps, FilePreview } from '@/models/interfaces'

// accepts file, props, and parameters to create a file preview object
const generateFilePreviewObject = (
  file: File,
  fileProps: NewFileProps,
  hasHeaders = true,
  rowsPreviewed = 5
): Promise<FilePreview> => {
  const filePreviewObj: FilePreview = {
    headers: [],
    body: [],
    errors: [],
    count: 0,
    isValid: false,
  }
  // File read to show user preview of file and front-end validate
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('No file provided')
    }
    reader.onload = () => {
      const readResult = reader.result as string
      const outputRaw = readResult.trim().split(/\r?\n/g) // split on new lines

      // remove any non-standard characters from first line (i.e. BOM)
      const firstLine = outputRaw[0]
      let outputLine = ''
      for (let i = 0; i < firstLine.length; i++) {
        if (firstLine.charCodeAt(i) <= 127) {
          outputLine += firstLine.charAt(i)
        }
      }
      outputRaw[0] = outputLine
      const delimiter = ','
      const firstRow = outputRaw[0].split(delimiter)

      // get headers, if any
      let headers = firstRow
      if (!hasHeaders) {
        headers = []
        for (let i = 1; i < firstRow.length + 1; i++) {
          headers.push('column' + i)
        }
      }

      // count the number of lines in the file
      filePreviewObj.count = hasHeaders
        ? outputRaw.length - 1
        : outputRaw.length

      // generate a few lines to preview and validate
      if (filePreviewObj) {
        filePreviewObj.headers = headers
        filePreviewObj.body = outputRaw.slice(1, rowsPreviewed + 1).map((v) => {
          const values = v.split(delimiter)
          return headers.reduce(
            (obj: { [key: string]: number | string }, header, index) => (
              (obj[header] = values[index]), obj
            ),
            {}
          )
        })

        // VALIDATION
        // headers validation
        if (hasHeaders) {
          let emailColumn = false
          let columnsWithoutHeader = 0
          let zipColumn = false
          headers.forEach((value) => {
            // remove any delimiter quotes
            if (
              value.charAt(0) === '"' &&
              value.charAt(value.length - 1) === '"'
            ) {
              value = value.substr(1, value.length - 2)
            }
            if (!value) {
              columnsWithoutHeader++
            }
            if (value.toUpperCase() === 'EMAIL') {
              emailColumn = true
            }
            if (
              value.toUpperCase() === 'ZIP' ||
              value.toUpperCase() === 'ZIPCODE'
            ) {
              zipColumn = true
            }
          })

          // error messages
          if (columnsWithoutHeader > 0) {
            filePreviewObj.errors.push(
              'Columns without headers: ' + columnsWithoutHeader
            )
          }
          if (!emailColumn) {
            filePreviewObj.errors.push('No email column detected')
          }
          if (!zipColumn && fileProps.zipColumn) {
            filePreviewObj.errors.push('No zip/zipcode column detected')
          }
        } // end hasHeaders check
      } // end filePreview check
      if (filePreviewObj.errors.length === 0) {
        filePreviewObj.isValid = true
      }
      resolve(filePreviewObj)
    }
    // start reading the file. When it is done, calls the onload event defined above.
    reader.readAsBinaryString(file)
  })
}

export { generateFilePreviewObject }
