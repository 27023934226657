var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" Compare your list"+_vm._s(_vm.emailListToCompare ? ' with ' + _vm.emailListToCompare.name : '')+" ")])]),_c('v-card-text',{staticClass:"body-1"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Upload your list to see how many names on this list would be new to you. ")]),(_vm.selectedState)?_c('p',[_c('v-checkbox',{attrs:{"label":("Compare only against " + _vm.selectedStateText + " names")},model:{value:(_vm.selectedStateCompare),callback:function ($$v) {_vm.selectedStateCompare=$$v},expression:"selectedStateCompare"}})],1):_vm._e(),_c('p',[_c('strong',[_vm._v("Format:")]),_vm._v(" one email per line, no quotes, no headers ")]),_c('v-file-input',{attrs:{"show-size":"","accept":".csv","label":"Your list as a CSV"},on:{"change":_vm.selectFile},model:{value:(_vm.newFile),callback:function ($$v) {_vm.newFile=$$v},expression:"newFile"}},[_c('template',{slot:"append"},[_c('v-btn',{staticClass:"mr-0 ml-2 mb-1 p-0",attrs:{"outlined":"","color":"primary","disabled":_vm.newFileHashProgress === 100 || !_vm.newFile},on:{"click":function($event){return _vm.previewFile(_vm.newFile, _vm.newFileProps, false)}}},[_vm._v(" preview ")]),_c('v-btn',{staticClass:"mr-0 ml-2 mb-1 p-0",attrs:{"color":"primary","disabled":_vm.newFileHashProgress === 100 ||
                  _vm.isHashingInProgress ||
                  !_vm.newFile ||
                  (_vm.filePreview.headers && !_vm.filePreview.headers.length),"loading":_vm.isHashingInProgress},on:{"click":function($event){return _vm.hashBuyerList()}}},[_vm._v(" hash ")])],1)],2),(_vm.newFileHashProgress)?_c('v-progress-linear',{class:[_vm.newFileHashProgress >= 100 ? '' : 'animate'],attrs:{"color":"secondary","height":"16","value":_vm.newFileHashProgress,"striped":"","stream":""}}):_vm._e(),(_vm.newFileUploadProgress)?_c('v-progress-linear',{class:[_vm.newFileUploadProgress >= 100 ? '' : 'animate'],attrs:{"color":"primary","height":"16","value":_vm.newFileUploadProgress,"striped":"","stream":""}}):_vm._e()],1)],1),(_vm.filePreview.body.length > 0)?_c('v-row',[_c('v-col',[(
              _vm.filePreview.count >= _vm.largeFileAlertCount &&
              _vm.newFileHashProgress < 100
            )?_c('v-alert',{attrs:{"border":"bottom","text":"","type":"info"}},[_vm._v(" This looks like a pretty large file! The hash process may take awhile."),_c('br'),_vm._v(" Once started, hang tight while it does it's thing. ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Records: "+_vm._s(_vm.filePreview.count.toLocaleString()))]),_c('p',{staticClass:"caption"},[_vm._v("Preview of first few rows")])]),_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.filePreview.body),function(row,index){return _c('tr',{key:index},_vm._l((row),function(value){return _c('td',{key:value},[(_vm.buyerListHashed.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.buyerListHashed[index] ? _vm.buyerListHashed[index].substr(7) : '')+" ")]):_c('span',[_vm._v(_vm._s(value))])])}),0)}),0)])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Hash progress: "),(_vm.newFileHashProgress !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.newFileHashProgress)+"% ")]):_c('span',[_vm._v(" Not started ")])]),_c('p',[_vm._v(" Lines hashed: "+_vm._s(_vm.buyerListHashed.length)+" ")])]),_c('v-col',[_c('p',[_vm._v(" New names: "+_vm._s(_vm.currentBuyerList && _vm.currentBuyerList.newNames !== null ? _vm.currentBuyerList.newNames.toLocaleString() : 'unknown')+" ")]),_c('p',[_vm._v(" Status: "),(_vm.currentBuyerList && _vm.currentBuyerList.fileProcessing)?_c('span',[_vm._v(" Comparing your list, please wait... ")]):_vm._e(),(_vm.currentBuyerList && _vm.currentBuyerList.newNames !== null)?_c('span',[_vm._v(" Comparison complete ")]):_vm._e(),(
                _vm.currentBuyerList &&
                !_vm.currentBuyerList.fileProcessing &&
                _vm.currentBuyerList.newNames === null
              )?_c('span',[_vm._v(" Not started ")]):_vm._e()])])],1),(_vm.currentBuyerList)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"border":"bottom","text":"","type":"success"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.currentBuyerList.newNames)+" "+_vm._s(_vm.currentBuyerList.stateSelection ? _vm.states.find(function (state) { return state.value === _vm.currentBuyerList.stateSelection }).text : '')+" names are new to your list: "+_vm._s(_vm.currentBuyerList.buyerListFileName)+"!"),_c('br'),_vm._v(" We've created a file for you with these names."),_c('br'),_vm._v(" Email us at "),_c('a',{attrs:{"href":"mailto:purchase@mobilization.market"}},[_vm._v("purchase@mobilization.market")]),_vm._v(" to complete your purchase! ")])])],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":(_vm.currentBuyerList && _vm.currentBuyerList.fileProcessing) ||
        _vm.uploading === true,"disabled":_vm.newFileHashProgress < 100 || _vm.uploading === true},on:{"click":function($event){return _vm.uploadBuyerList()}}},[_vm._v(" Upload hash file and compare ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeCompare}},[_vm._v(" Cancel ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" In addition to "),_c('a',{attrs:{"href":"https://cloud.google.com/security/compliance"}},[_vm._v(" Google Cloud's industry-leading security")]),_vm._v(" including third-party audits and certifications, your file contents are hashed in your browser before being uploaded and processed. Once processed, your hashed file is deleted from our servers. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }