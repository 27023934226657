





































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
  EmailListData,
  BuyerListData,
  FilePreview,
  NewFileProps,
} from '@/models/interfaces'

import { generateFilePreviewObject } from '@/libs/GenerateFilePreviewObject'
import { statesDropdown } from '@/libs/StatesDropdown'

import { getFirestore, doc, getDoc } from '@firebase/firestore'
import { getStorage, ref, uploadBytesResumable } from '@firebase/storage'

export default Vue.extend({
  name: 'EmailListCompare',
  props: {
    emailListToCompare: {
      type: Object as () => EmailListData | null,
    },
    selectedState: {
      type: String as () => string,
    },
  },
  data: (): {
    selectedStateCompare: boolean
    states: { text: string; value: string }[] // Vuetify dropdown type
    newFile: File | null
    newFileProps: NewFileProps
    newFileLineCount: number
    newFileHashProgress: number | null
    newFileUploadProgress: number
    buyerListHashed: string[]
    largeFileAlertCount: number
    uploading: boolean
    filePreview: FilePreview | null
    filePreviewDefault: FilePreview
  } => {
    return {
      selectedStateCompare: false,
      states: statesDropdown,
      newFile: null,
      newFileProps: {
        zipColumn: false,
      },
      newFileLineCount: 0,
      newFileHashProgress: null,
      newFileUploadProgress: 0,
      buyerListHashed: [],
      largeFileAlertCount: 500000,
      uploading: false,
      filePreview: null,
      filePreviewDefault: {
        headers: [],
        body: [],
        errors: [],
        count: 0,
        isValid: false,
      },
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions', 'getBuyerListsOwn']),
    isHashingInProgress() {
      if (this.newFileHashProgress !== null) {
        // then hasing has been started
        if (this.buyerListHashed.length === this.newFileLineCount) {
          return false
        }
        return true
      }
      return false
    },
    selectedStateText(): string {
      const stateObj = this.states.find((state) => {
        return state.value === this.selectedState
      })
      return stateObj ? stateObj.text : 'Unknown state'
    },
    currentBuyerList(): EmailListData | null {
      // a buyer may have already run a compare against the compare list
      // if so, then return the existing "buyer list" stats
      // otherwise return null
      if (this.getBuyerListsOwn.length > 0) {
        return this.getBuyerListsOwn
          .filter((buyerList: BuyerListData) => {
            if (this.emailListToCompare) {
              return buyerList.sellerListId === this.emailListToCompare.id
            } else {
              return false
            }
          })
          .sort((a: BuyerListData, b: BuyerListData) => {
            return b.timestampUpdated.seconds - a.timestampUpdated.seconds
          })[0] // get the first list from the reverse sorted array of list compares
      } else {
        return null
      }
    },
  },
  watch: {
    selectedState() {
      // console.log('watching selectedState')
      if (this.selectedState) {
        this.selectedStateCompare = true
      }
    },
  },
  methods: {
    closeCompare() {
      this.$emit('close-modal', true)
    },
    resetFilePreview() {
      this.filePreview = JSON.parse(JSON.stringify(this.filePreviewDefault))
    },
    selectFile(file: File) {
      this.newFileReset()
      this.newFile = file
      // this.resetFilePreview()
    },
    async previewFile(file: File, fileProps: NewFileProps, hasHeaders = true) {
      // reset filePreview object
      this.resetFilePreview()
      this.filePreview = await generateFilePreviewObject(
        file,
        fileProps,
        hasHeaders,
        5
      )
    },
    newFileReset() {
      this.resetFilePreview()
      this.newFile = null
      this.newFileHashProgress = null
      this.buyerListHashed.length = 0
      this.newFileLineCount = 0
      this.newFileUploadProgress = 0
    },
    async hashBuyerList() {
      // validation
      if (!this.newFile) {
        console.log('Did not find buyer list')
        return
      }
      if (!this.emailListToCompare) {
        console.log('Found no list to compare with')
        return
      }

      const progressMin = 3

      this.newFileLineCount = 0
      this.newFileHashProgress = progressMin
      this.buyerListHashed = []
      const reader = new FileReader()

      // get the salt for the seller list
      const sellerListId = this.emailListToCompare.id as string
      let sellerHashSalt = ''
      const firestore = getFirestore()
      const sellerRef = doc(
        firestore,
        'emailLists',
        sellerListId,
        'special',
        'hashSalt'
      )
      const sellerHashSaltDoc = await getDoc(sellerRef)
      if (sellerHashSaltDoc.exists()) {
        sellerHashSalt = sellerHashSaltDoc.data().hashSalt
      } else {
        console.log('No seller hash salt doc found')
        return
      }

      reader.onload = async () => {
        const readResult = reader.result as string
        let outputRaw = readResult.trim().split(/\r?\n/g) // split on new lines
        this.newFileLineCount = outputRaw.length

        // remove any non-standard characters from first line (i.e. BOM)
        let firstLine = outputRaw[0]
        let outputLine = ''
        for (let i = 0; i < firstLine.length; i++) {
          if (firstLine.charCodeAt(i) <= 127) {
            outputLine += firstLine.charAt(i)
          }
        }
        outputRaw[0] = outputLine

        // once supported natively in browsers, make this worker a module
        const hashWorker = new Worker('workers/hashEmailsWorker.js')
        const chunkSize = Math.ceil(outputRaw.length / 100)
        // console.log('chunk size: ', chunkSize)
        const buffer: string[] = []
        // send chunks of data to worker instead of one at a time
        // in order to avoid overhead of messaging and UI output
        for (let i = 0; i < outputRaw.length; i++) {
          buffer.push(outputRaw[i])
          // if buffer is full, or if we're out of items to fill the buffer
          // then post to web worker and clear buffer
          if (buffer.length === chunkSize || i === outputRaw.length - 1) {
            hashWorker.postMessage({
              emails: JSON.stringify(buffer),
              salt: sellerHashSalt,
            })
            buffer.splice(0, buffer.length)
          }
        }
        hashWorker.onmessage = (event) => {
          console.log('message received')
          // append the latest hash array to existing
          // a forEach loop may be faster than [...event.data]
          for (let i = 0; i < event.data.length; i++) {
            this.buyerListHashed.push(event.data[i])
          }
          // this.buyerListHashed = [...this.buyerListHashed, ...event.data]
          const progress = Math.floor(
            (this.buyerListHashed.length / outputRaw.length) * 100
          )
          this.newFileHashProgress =
            progress > progressMin ? progress : progressMin
        }
      }
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(this.newFile)
    },
    uploadBuyerList() {
      // validation
      if (this.newFileHashProgress && this.newFileHashProgress < 100) {
        console.log('Hashing not complete')
        return
      }
      if (!this.buyerListHashed.length) {
        console.log('No hash file to upload')
        return
      }
      const sellerListId = this.emailListToCompare
        ? (this.emailListToCompare.id as string)
        : ''
      if (!sellerListId) {
        console.log('No seller list id')
      }

      // set state (for button)
      this.uploading = true

      // path is buyer_lists/{userId}/{sellerListId}
      const fileFullPath = 'buyer_lists/' + this.getUser.id + '/' + sellerListId

      const metadata = {
        customMetadata: {
          ownerId: this.getUser.id,
          listId: sellerListId,
          unprocessed: 'true',
          listType: 'buyerList',
          stateSelection: this.selectedStateCompare ? this.selectedState : '',
          fileName: this.newFile ? this.newFile.name : '',
        },
      }

      // append a delimiter character to each hash
      // cannot use .join because might create string too large for Javascript
      const delimiter = ';'
      for (let i = 0; i < this.buyerListHashed.length; i++) {
        this.buyerListHashed[i] += delimiter
      }
      // convert array into a blob for upload
      const uploadBlob = new Blob(this.buyerListHashed, {
        type: 'text/plain',
      })

      // console.log('metadata', metadata)
      if (!metadata) {
        console.error('No metadata present')
      }
      const storage = getStorage()
      const storageRef = ref(storage, fileFullPath)
      const uploadTask = uploadBytesResumable(storageRef, uploadBlob, metadata)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          this.newFileUploadProgress = progress
        },
        (error) => {
          console.log(error)
        },
        () => {
          console.log('Upload complete')
        }
      )
      uploadTask.then(() => {
        this.newFileReset()
        this.uploading = false
      })
    },
  },
  created() {
    if (this.selectedState) {
      this.selectedStateCompare = true
    }
    // set the file preview to the default
    this.resetFilePreview()
  },
})
