


















































































































import Vue from 'vue'
import EmailListCompare from '@/components/EmailListCompare.vue'
import { mapGetters, mapActions } from 'vuex'
import { EmailListData } from '@/models/interfaces'

import { statesDropdown } from '@/libs/StatesDropdown'

export default Vue.extend({
  name: 'EmailListsForSale',
  components: {
    EmailListCompare,
  },
  data: (): {
    search: string
    itemsPerPage: number
    footerProps: {
      itemsPerPageOptions: number[]
    }
    dialogCompare: string[] // array of list of ids for each list compare modal
    tagSearch: string[]
    states: { text: string; value: string }[] // Vuetify dropdown type
    selectedState: string
    activeTag: string
    compareList: EmailListData | null
  } => {
    return {
      search: '',
      itemsPerPage: 20,
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
      dialogCompare: [],
      tagSearch: [],
      states: statesDropdown,
      selectedState: '',
      activeTag: '',
      compareList: null,
    }
  },
  computed: {
    ...mapGetters([
      'getEmailListsForSale',
      'getBuyerListsOwn',
      'getUser',
      'getUserPermissions',
    ]),
    emailLists(): EmailListData[] {
      if (this.selectedState) {
        return this.getEmailListsForSale.filter((list: EmailListData) => {
          return list.stateCount && list.stateCount[this.selectedState] > 0
        })
      }
      return this.getEmailListsForSale
    },
    emailListsHeader(): { text: string; value: string }[] {
      const headers = [
        { text: 'List Name', value: 'name' },
        { text: 'Price per name', value: 'pricePerName' },
        { text: 'Count', value: 'count' },
        { text: 'Description', value: 'description' },
        { text: 'Tags', value: 'tags' },
        { text: '', value: 'actions' },
      ]
      return headers
    },
    noDataText() {
      let text = ''
      if (!this.getUser) {
        text = 'Checking for permissions...'
      } else {
        text = 'Loading lists...'
      }
      return text
    },
    selectedStateText(): string {
      const stateObj = this.states.find((state) => {
        return state.value === this.selectedState
      })
      return stateObj ? stateObj.text : 'Unknown state'
    },
  },
  watch: {
    getUserPermissions() {
      // see if we need to load (or 'unload') email lists
      this.loadList()
    },
  },
  methods: {
    ...mapActions(['bindEmailListsToDb', 'bindBuyerListsToDb']),
    loadList() {
      if (
        this.getUserPermissions &&
        (this.getUserPermissions.role === 'member' ||
          this.getUserPermissions.role === 'admin')
      ) {
        if (!this.getEmailListsForSale.length) {
          this.bindEmailListsToDb({ viewSet: 'forSale' })
          this.bindBuyerListsToDb({ viewSet: 'own' })
        }
      }
    },
    tagClickSearch() {
      this.search = this.activeTag
    },
    closeCompare(itemId: string) {
      // set dialogCompare to a new array that excludes the list's itemId
      this.dialogCompare = this.dialogCompare.filter((e) => e !== itemId)
    },
  },
  created() {
    this.loadList()
  },
})
