<template>
  <v-row align="stretch" align-content="start" justify="center" no-gutters>
    <v-col>
      <EmailListsForSale />
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import EmailListsForSale from '@/components/EmailListsForSale.vue'

export default {
  name: 'BuyEmailLists',
  components: {
    EmailListsForSale,
  },
}
</script>
